import React from "react"
import { v4 as uuidv4 } from "uuid"

/* This example requires Tailwind CSS v2.0+ */
import {
  LightningBoltIcon,
  ShareIcon,
  LightBulbIcon,
  EmojiHappyIcon,
  CheckCircleIcon,
  UsersIcon,
} from "@heroicons/react/outline"

import team_full from "../../images/team_full.jpg"

const features = [
  {
    name: "Innovativ",
    description:
      "Um Ihre Wünsche technisch einwandfrei zu erfüllen, gehen wir auch jeder Zeit gerne andere Wege. ",
    icon: LightningBoltIcon,
  },
  {
    name: "Dynamisch",
    description:
      "Das Geheimrezept ist und bleibt geheim, doch unsere langjährigen Erfahrungen und Partnerschaften sind der Grundstein für kleine und grosse Projekte.",
    icon: ShareIcon,
  },
  {
    name: "Zukunftsorientiert",
    description:
      "Fachwissen und Personal sind unser wichtigstes Gut. Um stets auf dem neusten Stand der Technik zu sein, bilden wir unsere Mitarbeitenden intern sowie extern weiter. ",
    icon: CheckCircleIcon,
  },
  {
    name: "Lösungsorientiert",
    description:
      "Die Wünsche unserer Kunden stehen bei uns immer im Fokus. Um die Designvorstellungen und die Funktionalität zu gewährleisten, erarbeiten wir individuelle Lösungen. ",
    icon: LightBulbIcon,
  },
  {
    name: "Partnerschaftlich",
    description:
      "Gutes kann nur gemeinsam entstehen, genau aus diesem Grund verhalten wir uns gegenüber anderen aufrichtig und ehrlich im Gegenzug erwarten wir das auch. ",
    icon: UsersIcon,
  },
  {
    name: "Kompetent",
    description:
      "Um den Herausforderungen unserer Kunden bestmöglichst zu begegnen, arbeiten Projektleiter aus den unterschiedlichen Bereichen in zusammengestellten Teams gemeinsam an einer Lösung.",
    icon: EmojiHappyIcon,
  },
]

export default function CompanyFeatures() {
  return (
    <>
      <div className="relative bg-white">
        <p className="mt-2 mb-4 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Unsere Werte
        </p>
        <img src={team_full} className="rounded-xl w-full" />
        <p className="mt-5 max-w-prose text-xl text-gray-500">
          Das entgegengebrachte Vertrauen behandeln wir aufrichtig und ehrlich,
          unsere Aufgaben führen wir lösungsorientiert, präzise und
          termingerecht mit der geforderten Dynamik aus.
        </p>

        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map(feature => (
              <div key={uuidv4()} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-blue-500 rounded-md shadow-lg">
                        <feature.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-bold text-gray-900 tracking-tight">
                      {feature.name}
                    </h3>
                    <p className="mt-2 text-base text-gray-500">
                      {feature.description}{" "}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
