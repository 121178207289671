import * as React from "react"
import CompanyFeatures from "../components/company-features"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Team from "../components/team"

const UeberUnsPage = () => (
  <Layout>
    <Seo title="Über uns" />
    <div className="max-w-7xl mx-auto px-4 py-4 md:px-0 md:py-12">
      <CompanyFeatures />
    </div>
    <Team />
  </Layout>
)

export default UeberUnsPage
